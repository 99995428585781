import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"

const getAction = (
  next: Function
) => {
  return async (data: any) => {
    const href = `/leads/${data.item.attributes.taskable.id}`
    await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
    // Reload the data (tiles and table)
    next()
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.cgName",
    label: "Betreuungskraft",
    sortable: true,
    // options: {hrefFunction: (data: any) => `/care_givers/${data.item.meta.tableRow.careGiverId}`}
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.tableRow.createdAt",
  //   label: "Erhalten",
  //   sortable: true,
  //   options: {type: 'date'}
  // },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.agencyName",
    label: "Agentur",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.posting",
    label: "Stelle",
    sortable: true,
    options: {
      contentFunction: (data: any) => 'Link',
      hrefFunction: (data: any) => {
        const tr = data.item.meta.tableRow
        if (tr.contractPresent) {
          return resourceUrl(tr.contract)
        } else {
          return resourceUrl(tr.posting)
        }
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.hhName",
    label: "Haushalt",
    sortable: true,
    options: {hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.household)}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.suggestionCount",
    label: "#",
    sortable: true,
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
  //   key: "actions",
  //   label: "Aktionen",
  //   sortable: false,
  //   options: {
  //     buttons: [
  //       {
  //         title: 'Absenden',
  //         icon: ['fa', 'check'],
  //         action: getAction(next)
  //       },
  //     ]
  //   }
  // }
]}