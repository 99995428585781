import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";

const getAction = (
  next: Function
) => {
  return (data: any) => {
    const href = `/leads/${data.item.meta.leadId}`
    apiClient.patch(href, { usecase: 'fetch_lead' }).then((r) => {

    })
    // Reload the data (tiles and table)
    next(data.item.meta.leadId)
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.age",
  //   label: "Alter",
  //   tooltip: "Zeit seitdem der Lead eingetroffen ist",
  //   sortable: true,
  //   options: {
  //     // cellClassFunction: (data:any) => {
  //     //   if (!data.item.meta.tableRow.feedback.id) return 'text-danger'
  //     //   return feedbackColorClass(data.item.meta.tableRow.feedback)
  //     // },
  //     contentFunction: (data:any) => {
  //       const ca = data.item.meta.leadCreatedAt
  //       const nsec = new Date().getTime()
  //       const caSec = Date.parse(ca)
  //       const diffSecs = (nsec-caSec)/1000
  //       const m = Math.floor((diffSecs%3600)/60)
  //       const s = Math.floor(diffSecs%3600%60)
  //       return `${m}m:${s}s`
  //     }
  //   }
  // },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.leadPrice",
    label: "Preis",
    sortable: true
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.leadZipCode",
  //   label: "Plz.",
  //   sortable: true
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "meta.leadGender",
  //   label: "Geschlecht"
  // },
  {
    component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    key: "actions",
    label: "Aktionen",
    sortable: false,
    options: {
      buttons: [
        {
          title: 'Kaufen',
          icon: ['fas', 'cart-plus'],
          action: next
        },
      ]
    }
  }
]}