import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"

const getAction = (
  next: Function
) => {
  return async (data: any) => {
    const href = `/leads/${data.item.attributes.taskable.id}`
    await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
    // Reload the data (tiles and table)
    next()
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.leadName",
    label: "Lead",
    sortable: true,
    options: {
      hrefFunction: (data: any) => `/leads/${data.item.meta.tableRow.leadId}`,
      contentFunction: (data: any) => (data.item.meta.tableRow.leadName || 'Nicht Angegeben')
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.crName",
    label: "Pflegebedürftige",
    sortable: true,
    options: {
      hrefFunction: (data: any) => `/care_receivers/${data.item.meta.tableRow.crId}`,
      contentFunction: (data: any) => (data.item.meta.tableRow.crName || 'Nicht Angegeben')
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.note",
    label: "Notiz",
    sortable: false,
    // options: {type: 'date'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.dueAt",
    label: "Termin",
    sortable: true,
    options: {type: 'datetime'}
  },
]}