
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue";
  import Tile from "@/components/Tile.vue"
  import { Tile as TileModel } from "@/models/dashboard";
  import { Lead } from "@/models/leads"
  import { Contract } from "@/models/contracts"
  import { CareReceiver } from "@/models/care_receivers"
  import { Suggestion } from "@/models/suggestions"
  import { Task } from "@/models/tasks"
  import { Ticket } from "@/models/tickets"
  import { Household } from "@/models/households"
  import { Posting } from "@/models/postings"
  import apiClient from '@/api/apiClient'
  import { Column } from "@/models/table";
  import { getRefreshData, initializeModal, addDefaultTaskColumns, addAction } from "@/view_scripts/dashboardUtils";
  import { stringSort } from "@/utils/helper";
  import { Data, Props } from "@/views/shared/dashboard/dashboard.interface";
  import { getColumns as sLBuyableColumns } from "@/views/seller/dashboard/s_l_buyable";
  import { getColumns as sLNewColumns } from "@/views/seller/dashboard/s_l_new";
  import { getColumns as sLUncontacted } from "@/views/seller/dashboard/s_l_uncontacted";
  import { getColumns as sCNewsugColumns } from "@/views/seller/dashboard/s_c_newsug";
  import { getColumns as sCSignColumns } from "@/views/seller/dashboard/s_c_sign";
  import { getColumns as sCSwapnearColumns } from "@/views/seller/dashboard/s_c_swapnear";
  import { getColumns as sCrIncompColumns } from "@/views/seller/dashboard/s_cr_incomp";
  import { getColumns as sPNewsugColumns } from "@/views/seller/dashboard/s_p_newsug";
  import { getColumns as sHContactColumns } from "@/views/seller/dashboard/s_h_contact";
  import { getColumns as sHNewPostColumns } from "@/views/seller/dashboard/s_h_newpost";
  import { getColumns as sSWaitColumns } from "@/views/seller/dashboard/s_s_wait";
  import { getColumns as sSCancelledColumns } from "@/views/seller/dashboard/s_s_cancelled";
  import { getColumns as sSSwapCancelledColumns } from "@/views/seller/dashboard/s_s_swapcancelled";
  import { getColumns as sSConfirmedColumns } from "@/views/seller/dashboard/s_s_confirmed";
  import { getColumns as sPNoSugColumns } from "@/views/seller/dashboard/s_p_nosug";
  import { getColumns as sTNotifiedColumns } from "@/views/seller/dashboard/s_t_notified";
  import { getColumns as sVRejectedColumns } from "@/views/seller/dashboard/s_v_rejected";
  import { getColumns as sVFeedbackCreated } from "@/views/seller/dashboard/s_v_feedback_created";
  import TaskEditModal from "@/components/shared/modals/TaskEditModal.vue"
  import TicketModal from "@/views/internal/dashboard/modals/TicketModal.vue"
  import { lastTicketActivity } from "@/utils/dataExtractors";
  import { confirm } from '@/utils/interactionModals'

  export default defineComponent({
    components: { Tile },
    props: { baseData: Array },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const smsOnNewLead = ref(false)
      const emailOnNewLead = ref(false)
      const orderedTileIds = ['s_l_buyable']
      const orderedTiles = ref<TileModel[]>([])
      const activeTile = ref<TileModel>()
      const setActiveTile = (tile: TileModel) => {
        activeTile.value = tile
        processTileAction()
      }
      const showLoading = ref(false)
      const processTileAction = async () => {
        const tile = activeTile.value
        const response = await apiClient.get(tile?.links[tile.sysId])
        response.data.data = stringSort(response.data.data, ((r) => Date.parse(r.attributes.createdAt)), false)
        updateTableItems(response.data)
      }

      watch(() => props.baseData, (newValue) => {
        if (!newValue) return
        const copy = [...newValue]
        const startTile = copy.find((t) => t.sysId === 's_l_buyable')
        if (startTile && !activeTile.value) setActiveTile(startTile)
        orderedTiles.value = copy.sort((a, b) => {
          return orderedTileIds.indexOf(b.sysId) - orderedTileIds.indexOf(a.sysId)
        })
      }, { immediate: true })
      watch(() => root.$store.state.users.currentUser, (newVal) => {
        smsOnNewLead.value = newVal.data?.attributes?.settings?.leadShop?.smsOnNewLead
        emailOnNewLead.value = newVal.data?.attributes?.settings?.leadShop?.emailOnNewLead
      })

      // Table
      const showTable = ref(false)
      const tableName = ref()
      const columns = ref<Column[]>([])
      const modalColumns = ref<Column[]>([])
      const tableItems = ref<any[]>([])
      const refreshData = getRefreshData()

      // Modal
      const update = () => {
        refreshData()
        processTileAction()
      }
      const { modalType, modalProps, addReminderAction, addDestroyTaskAction } = initializeModal(root, update)
      const modalTableItems = ref<any[]>([])
      const modalClosed = (reload?: boolean) => {
        modalProps.value = { visible: false }
        modalType.value = undefined
        if (reload) {
          refreshData()
          processTileAction()
        }
      }

      const updateTableItems = (
        data: Data
      ) => {
        let cols: any
        let nextAction
        switch (activeTile.value?.sysId) {
          case 's_l_buyable':
            tableName.value = 'Verfügbare Leads'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = (tableRowData) => {
              const leadId = tableRowData.item.meta.leadId
              const href = `/leads/${leadId}`
              showLoading.value = true
              apiClient.patch(href, { usecase: 'fetch_lead' }).then((r) => {
                root.$router.push(`leads/${leadId}`)
              }).catch(() => showLoading.value = false)
            }
            cols = sLBuyableColumns(data as any, nextAction)
            break
          case 's_l_new':
            tableName.value = 'Neue Leads'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sLNewColumns(data as any, nextAction)
            addReminderAction(cols)
            break
          case 's_l_uncontacted':
            tableName.value = 'Nicht erreichte Leads'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sLUncontacted(data as any, nextAction)
            addReminderAction(cols)
            break
          case 's_l_nocr':
            tableName.value = 'Erreichte Leads ohne EB'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sLNewColumns(data as any, nextAction)
            addReminderAction(cols)
            break
          case 's_c_sign':
            tableName.value = 'Verträge ohne Unterschrift'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCSignColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_c_arrival':
            tableName.value = 'Anreise klären'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCSignColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_c_newcs':
            tableName.value = 'Neue Wechselvorschläge'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCNewsugColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_c_nopdf':
            tableName.value = 'Verträge ohne Vertragsdokument'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCSignColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_c_swapnear':
            tableName.value = 'BK Wechsel naht'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCSignColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_cr_incomp':
            tableName.value = 'Erfassungsbogen unvollständig'
            tableItems.value = (data.data as Task<CareReceiver>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCrIncompColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_cr_free':
            tableName.value = 'Pflegebedürftige ohne Haushalt'
            tableItems.value = (data.data as Task<CareReceiver>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCrIncompColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_h_newpost':
            tableName.value = 'Stellenausschreibung erstellen'
            tableItems.value = (data.data as Task<Household>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sHNewPostColumns()
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_h_contact':
            tableName.value = 'Kontaktadresse des Haushalts festlegen (z.B. für Paket)'
            tableItems.value = (data.data as Task<Household>[])
            nextAction = (addressSelect: string) => {
              return async (data: any) => {
                const payload = {
                  id: data.item.meta.tableRow.hh.id,
                  body: {attributes: {contactAddressId: data.item.meta.tableRow[addressSelect+'AddressId']}}
                }
                const addType = addressSelect === 'lead' ? 'Lead' : 'PB'
                const confirmation = await confirm(root, addType+' Addresse als Kontaktadresse des Haushalt auswählen?')
                if (confirmation) {
                  showLoading.value = true
                  await root.$store.dispatch('households/edit', payload)
                  refreshData()
                  processTileAction()
                  showLoading.value = false
                }
              }
            }
            cols = sHContactColumns(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_p_newcs':
            tableName.value = 'Neue Betreuungskraft Vorschläge'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sPNewsugColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_cs_wait':
            tableName.value = 'Warten auf Kundenentscheidung'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSWaitColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_cs_swapwait':
            tableName.value = 'Warten auf Kundenentscheidung (Wechselvorschlag)'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSWaitColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_cs_accepted':
            tableName.value = 'Warten auf die Bestätigung der BK durch die Agentur'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSWaitColumns(data as any, nextAction)
            addReminderAction(cols)
            break
          case 's_cs_swapaccepted':
            tableName.value = 'Warten auf die Bestätigung der BK durch die Agentur (Wechsel)'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSWaitColumns(data as any, nextAction)
            addReminderAction(cols)
            break
          case 's_cs_cancelled':
            tableName.value = 'BK Vorschlag zurück gezogen'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSCancelledColumns(data as any, nextAction)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_cs_swapcancelled':
            tableName.value = 'Wechselvorschlag zurück gezogen'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSSwapCancelledColumns(data as any, nextAction)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_cs_confirmed':
            tableName.value = 'BK bestätigt. Neuer Vertrag erstellt'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSConfirmedColumns(data as any, nextAction)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_cs_swapconfirmed':
            tableName.value = 'Wechselvorschlag bestätigt'
            tableItems.value = (data.data as Task<Suggestion>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSConfirmedColumns(data as any, nextAction)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_p_nocs':
            tableName.value = 'Stellenausschreibungen ohne Vorschläge'
            tableItems.value = (data.data as Task<Posting>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sPNoSugColumns()
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_p_novisor':
            tableName.value = 'Stellenausschreibungen ohne aktiven Agenturzugang'
            tableItems.value = (data.data)
            nextAction = (item: any, dupLeads: any[]) => {
              refreshData()
              processTileAction()
            }
            cols = sPNoSugColumns()
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 's_v_rejected':
            tableName.value = 'Anfrage durch die Agentur abgelehnt'
            tableItems.value = (data.data as Task<Posting>[])
            cols = sVRejectedColumns()
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_v_feedback_created':
            tableName.value = 'Anfrage-Feedback erhalten'
            tableItems.value = (data.data as Task<Posting>[])
            cols = sVFeedbackCreated()
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_t_notified':
            tableName.value = 'Tickets'
            tableItems.value = stringSort((data.data as Task<Ticket>[]), (i) => lastTicketActivity(i.attributes.taskable), false)
            nextAction = async (task: Task<Ticket>, action: string) => {
              modalType.value = TicketModal
              modalProps.value = {
                visible: true,
                action: action,
                ticket: task.attributes.taskable
              }
            }
            cols = sTNotifiedColumns(nextAction)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_l_man':
            tableName.value = 'Manuelle Lead Wiedervorlagen'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sLNewColumns(data as any, nextAction)
            cols.splice(1,3)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_cr_man':
            tableName.value = 'Manuelle EB Wiedervorlagen'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCrIncompColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_c_man':
            tableName.value = 'Manuelle Vertrags Wiedervorlagen'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sCSignColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_h_man':
            tableName.value = 'Manuelle Haushalt Wiedervorlagen'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sHNewPostColumns()
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_p_man':
            tableName.value = 'Manuelle Stellenausschreibung Wiedervorlagen'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sPNewsugColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_cs_man':
            tableName.value = 'Manuelle BK Vorschläge Wiedervorlagen'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sSWaitColumns(data as any, nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 's_t_man':
            tableName.value = 'Manuelle Ticket Wiedervorlagen'
            tableItems.value = (data.data as Task<Contract>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = sTNotifiedColumns(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          default:
            throw Error(`This Tile's sysId: ${activeTile.value?.sysId} does not exist.`)
        }
        columns.value = cols
        showTable.value = true
      }

      const updateUserSettings = () => {
        const currentUser = root.$store.state.users.currentUser
        if (currentUser && currentUser.data?.attributes?.settings) {
          const settings = currentUser.data?.attributes?.settings
          const newSettings = {"smsOnNewLead": smsOnNewLead.value, "emailOnNewLead": emailOnNewLead.value}
          const oldSettings = settings.leadShop||{}
          settings.leadShop = {...oldSettings, ...newSettings}
          showLoading.value = true
          console.log({settings})
          root.$store.dispatch('users/update_user_settings', { id: currentUser.data.id, settings: settings })
            .finally(() => showLoading.value = false)
        }
      }

      return {
        activeTile,
        setActiveTile,
        tableName,
        tableItems,
        showTable,
        columns,
        modalType,
        modalProps,
        modalClosed,
        modalColumns,
        modalTableItems,
        showLoading,
        smsOnNewLead,
        emailOnNewLead,
        updateUserSettings,
        orderedTiles
      }
    }
  })
