import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"

const getAction = (
  next: Function
) => {
  return async (data: any) => {
    const href = `/leads/${data.item.attributes.taskable.id}`
    await apiClient.patch(href, { usecase: 'assign_seller', seller_id: data.item.assignSeller.id })
    // Reload the data (tiles and table)
    next()
  }
}

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "attributes.taskable.meta.tableRow.fullName",
    label: "Name",
    sortable: true,
    options: {hrefFunction: (data: any) => resourceUrl(data.item.attributes.taskable)}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.meta.tableRow.leadsource",
    label: "Leadquelle",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.meta.tableRow.createdAt",
    label: "Erstellt",
    sortable: true,
    options: {type: 'date'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.meta.tableRow.createdAtSource",
    label: "Erstellt (quelle)",
    sortable: true,
    options: {type: 'datetime'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.dueAt",
    label: "Termin",
    sortable: true,
    options: {type: 'datetime'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Prio",
    key: "attributes.priority",
    options: { colorInverted: true }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.note",
    label: "Notiz",
    sortable: false,
  },
]}