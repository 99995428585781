// import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"

export const getColumns = () => [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.agencyName",
    label: "Agentur",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.rejectReason",
    label: "Grund",
    sortable: false,
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.household.attributes.designation",
    label: "Haushalt",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {
        return resourceUrl(data.item.meta.household)
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.posting.id",
    label: "Stelle",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {return resourceUrl(data.item.meta.posting)},
      contentFunction: (data: any) => 'Link'
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.meta.tableRow.archived",
  //   label: "Archiviert",
  //   sortable: true,
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.meta.tableRow.dormant",
  //   label: "Schläft",
  //   sortable: true,
  // },
]
