
  import { defineComponent, ref, watch, computed, getCurrentInstance } from "vue";
  import TicketMessage from "@/components/shared/TicketMessage.vue"
  import { Subscription } from '@/models/subscriptions'
  import { Data, Props, TileAction } from "@/views/shared/dashboard/dashboard.interface";;
  import { ResourceObject } from '@/models/jsonapi'

  export default defineComponent({
    components: {
      TicketMessage
    },
    props: {
      visible: {
        type: Boolean,
        default: true
      },
      action: {
        type: String,
        required: true
      },
      ticket: {
        type: Object,
        required: true
      }
    },
    setup(props: any, { emit }) {
      const root = getCurrentInstance().proxy
      const ticket = props.ticket
      const ticketMessage = ref({ payload: '', mailIncluded: true })

      const ticketMessages = computed(() => {
        const messages = ticket.attributes.messages as any[]
        return messages.sort((a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any))
      })

      const displayType = (type: string) => {
        if (type === 'Seller' || type === 'Assistant') {
          return 'Vertrieb'
        } else if (type === 'Internal') {
          return 'Innendienst'
        } else if (type === 'Provider') {
          return 'Agentur'
        } else {
          return 'Unbekannt'
        }
      }

      const subs = computed(() => ticket.attributes.subscriptions)
      const getType = (sub: any) => displayType(sub.attributes.protoUser.attributes['_type'])
      const modalVisible = ref(props.visible)
      watch(() => props.visible, (newValue) => {
        modalVisible.value = newValue
        // ticketMessage.value = ''
      }, { immediate: true })

      // const modalResolveAction = async (action: string) => {
      //   if (action === 'ok') {
      //     console.log('why is ok updating the task trivially?')
      //     await TasksRepository.edit(task.id, { note: task.attributes.note.value, dueAt: task.attributes.dueAt.value })
      //     emit('done', true)
      //   }
      // }

      const performTicketAction = async () => {
        if (props.action === 'view') {
          await createNewTicketMessage()
        } else if (props.action === 'archive') {
          await archiveTicket()
        }
        emit('done', true)
      }

      const createNewTicketMessage = async () => {
        ticket.attributes.messages.push(ticketMessage.value)
        await root.$store.dispatch('tickets/edit', { id: ticket.id, body: { attributes: ticket.attributes } })
      }

       const archiveTicket = async () => {
         await root.$store.dispatch('tasks/delete', props.task.id)
       }

      const subClicked = async (sub: any) => {
        await root.$store.dispatch('subscriptions/edit', { id: sub.id, body: { attributes: { active: !sub.attributes.active } } })
        ticket.attributes.subscriptions = subs.value.map(oldSub => {
          if (oldSub.id !== sub.id) return oldSub
          oldSub.attributes.active = !oldSub.attributes.active
          return oldSub
        })
      }

      return {
        modalVisible,
        // modalResolveAction,
        ticketMessage,
        ticketMessages,
        performTicketAction,
        subs,
        subClicked,
        getType
      }
    }
  })
