import { resourceUrl } from "@/utils/dataExtractors"

export const getColumns = (
  next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.hhDesignation",
    label: "Haushalt",
    sortable: true,
    options: { hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.hh) }
  },
  {
    component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    key: "actions",
    label: "Aktionen",
    sortable: false,
    options: {
      buttons: [
        {
          title: 'Leadadresse nutzen',
          icon: ['fa', 'phone'],
          action: next('lead')
        },
        {
          title: 'PB Adresse nutzen',
          icon: ['fa', 'bed'],
          action: next('cr')
        }
      ]
    }
  }
]
