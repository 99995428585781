// import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors"
import { feedbackType, feedbackColorClass } from "@/models/feedbacks";

export const getColumns = () => [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.agencyName",
    label: "Agentur",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.type",
    label: "Typ",
    sortable: true,
    options: {
      contentFunction: (data: any) => {
        return feedbackType({attributes: {type: data.item.meta.type}})
      },
      cellClassFunction: (data: any) => {
        return feedbackColorClass({attributes: {type: data.item.meta.type}})
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.household.attributes.designation",
    label: "Haushalt",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {
        return resourceUrl(data.item.meta.household)
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.visor.id",
    label: "Agenturzugang",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {return resourceUrl(data.item.meta.visor)},
      contentFunction: (data: any) => 'Link'
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.meta.tableRow.archived",
  //   label: "Archiviert",
  //   sortable: true,
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.meta.tableRow.dormant",
  //   label: "Schläft",
  //   sortable: true,
  // },
]
