// import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";

export const getColumns = (
  // data: Data,
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.hhDesignation",
    label: "Haushalt",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {
        return `/households/${data.item.meta.tableRow.householdId}`
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.note",
    label: "Notiz",
    sortable: false,
    // options: {type: 'date'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.dueAt",
    label: "Termin",
    sortable: true,
    options: {type: 'datetime'}
  },
]
