import { lastTicketActivity, resourceUrl } from "@/utils/dataExtractors";
import { modelCaseConversion } from "@/utils/apiDataHelper";
const viewAction = (
  next: Function
) => {
  return async (data: any) => {
    next(data.item, 'view')
  }
}

const archiveAction = (
  next: Function
) => {
  return async (data: any) => {
    next(data.item, 'archive')
  }
}

export const getColumns = (
  next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.subject",
    label: "Subject",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {
        return data.item.meta.tableRow.ticketLink
        // return `/tickets/${ticket.id}`
      },
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.leadName",
    label: "Lead",
    options: {
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.lead)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.ticketable.type",
    label: "Bezug",
    options: {
      contentFunction: (data: any) => modelCaseConversion(data.item.meta.tableRow.ticketable.type),
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.ticketable)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.attributes.category",
    label: "Kategorie",
    sortable: true
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Nachrichten",
    key: "meta.tableRow.messageCount",
    sortable: false,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Letzte Änderung",
    key: "meta.tableRow.lastChange",
    sortable: false,
    options: {
      type: 'datetime',
      // contentFunction: (data: any) => {
      //   return lastTicketActivity(data.item.attributes.taskable)
      // }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Termin Notiz",
    key: "attributes.note",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Termin",
    key: "attributes.dueAt",
    options: { type: 'datetime' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Prio",
    key: "attributes.priority",
    options: { colorInverted: true }
  },
]
