import { hmsString } from "@/utils/helper"

export interface Available {
  sendBy: "today" | "tomorrow" | "";
  sendByTime: string;
  comment?: string;
}

export interface Possible {
  german: boolean;
  germanComment?: string;
  price: boolean;
  priceComment?: string;
  gender: boolean;
  genderComment?: string;
  other: boolean;
  otherComment?: string;
  sendBy: "today" | "tomorrow" | "";
  sendByTime: string;
  comment?: string;
}

export interface MoreTime {
  comment?: string;
}

export interface Unavailable {
  permanent: boolean;
  budget?: boolean;
  budgetComment?: string;
  severity?: boolean;
  severityComment?: string;
  weight?: boolean;
  weightComment?: string;
  nightShifts?: boolean;
  nightShiftsComment?: string;
  contageous?: boolean;
  contageousComment?: string;
  expectations?: boolean;
  expectationsComment?: string;
  other?: boolean;
  otherComment?: string;
  comment?: string;
}

export interface QuickFeedback {
  id: string;
  response: Available | Possible | MoreTime | Unavailable;
}

export const timeLeft = (feedback: any, units: boolean = true) => {
  if (!feedback.id) return 'Bitte Stelle Einschätzen'
  const type = feedbackType(feedback)
  if (['Absage', 'Mehr Zeit'].includes(type)) return type
  const ea = Date.parse(feedback.attributes.expiresAt)
  const tn = new Date().getTime()
  const tl = (ea - tn)/1000
  if (tl<0) return '0'
  return hmsString(tl, units)
}

export const misMatchDict = (feedback: any) => {
  if (!feedback.id) return {}
  const fieldTranslation = {
    german: 'Deutschkenntnisse',
    price: 'Preis',
    gender: 'Geschlecht',
    budget: 'Budget',
    severity: 'Schwierigkeit',
    weight: 'Gewicht',
    night: 'Nachteinsätze',
    contagious: 'Ansteckungsgefahr',
    expectations: 'Erwartungen',
    other: 'Anderes'
  }
  return Object.entries(feedback.attributes).reduce((res, k) => {
    if (k[1] === true) res[fieldTranslation[k[0]]] = feedback.attributes[`${k[0]}Comment`]
    return res
  }, {})
}

export const feedbackType = (feedback: any)=>{
  switch (feedback.attributes?.type) {
    case 'positive':
      return 'Vorhanden!'
      break
    case 'possible':
      return 'Verhandeln'
      break
    case 'extension':
      return 'Mehr Zeit'
      break
    case 'negative':
      return 'Absage'
      break
    default:
      return ''
  }
}

export const feedbackDescription = (feedback: any)=>{
  switch (feedback.attributes?.type) {
    case 'positive':
      return 'Eine passende Betreuungskraft für die Stelle ist vorhanden und kann zum gewünschten Datum Anreisen.'
      break
    case 'possible':
      return 'Eine Betreuungskraft mit abweichenden Eigenschaften ist für die Stelle vorhanden.'
      break
    case 'extension':
      // return 'Aktuell ist keine passende Betreuungskraft verfügbar. Die Wahrscheinlichkeit einen passenden Vorschlag zu finden ist jedoch sehr hoch.'
      return 'Derzeit sind wir nicht im Stande die Anfrage zu bearbeiten. Sobald sich das ändert, melden wir uns bei Ihnen.'
      break
    case 'negative':
      return 'Es gibt keine passende Betreuungskraft und wir sehen keine Möglichkeit einen Vorschlag zu finden'
      break
    default:
      return ''
  }
}

export const feedbackColorClass = (feedback: any)=>{
  switch (feedback.attributes?.type) {
    case 'positive':
      return 'text-success'
      break
    case 'possible':
      return 'text-primary'
      break
    case 'extension':
      return 'text-warning'
      break
    case 'negative':
      return 'text-danger'
      break
    default:
      return ''
  }
}
