import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { fullName, resourceUrl } from "@/utils/dataExtractors"

export const getColumns = (
  data: Data,
  next: Function
) => {
  return [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.hhDesignation",
    label: "Stelle",
    sortable: true,
    options: {hrefFunction: (data: any) => resourceUrl(data.item.meta?.tableRow?.posting) }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.agencyName",
    label: "Vertrag mit",
    sortable: true,
    options: {hrefFunction: (data: any) => `/contracts/${data.item.meta.tableRow.contractId}`}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.currentCgiDeparture",
    label: "Abreise aktuelle Kraft",
    options: {type: 'date'}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.suggestionCount",
    label: "#Vorschläge",
  },
]}