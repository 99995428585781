// import apiClient from '@/api/apiClient'
import { Data } from "@/views/shared/dashboard/dashboard.interface";
import { resourceUrl } from "@/utils/dataExtractors";

export const getColumns = (
  // data: Data,
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.hhDesignation",
    label: "Von Haushalt",
    sortable: true,
    options: {
      hrefFunction: (data: any) => {
        return `/postings/${data.item.meta.tableRow.postingId}`
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.leadName",
    label: "Lead",
    sortable: true,
    options: {
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.lead)
    }
  },
]
